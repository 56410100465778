<template>
  <div class="p-dialog-content">
    <div><span class="p-confirm-dialog-message">{{ message }}</span></div>
    <div style="text-align: left" class="py-4">
      <span>Выберите код удаления: </span>
      <Dropdown v-model="selectedCode" :options="Object.keys(codes)" class="ml-2 w-full md:w-7rem"/>
    </div>
    <div><span class="p-confirm-dialog-message">{{ codes[selectedCode] }}</span></div>

  </div>
  <div class="p-dialog-footer">
    <Button label="Отмена" icon="pi pi-ban" @click="this.dialogRef.close"/>
    <Button label="Удалить" icon="pi pi-trash" :disabled="isAcceptButtonDisabled" @click="onAccept"/>
  </div>
</template>

<script>
export default {
  inject: ['dialogRef'],
  data() {
    return {
      message: "",
      accept: null,
      codes: {},
      selectedCode: "D.2"

    }
  },
  computed: {
    isAcceptButtonDisabled() {
      return this.selectedCode === "" || this.selectedCode === null || this.selectedCode === undefined;
    }
  },
  methods: {
    onAccept() {
      if (this.accept) {
        this.accept(this.selectedCode)
      }
      this.dialogRef.close()
    }
  },
  mounted() {
    const params = this.dialogRef.data;
    this.accept = params.accept
    this.message = params.message;
    const codes = this.$store.state.compendiums.deleteCreditHistoryCodes;
    for (const code of codes){
      this.codes[code.code] = code.value;
    }
  },
}
</script>