<template>
  <div>
    <DataTable
      ref="dt"
      :value="items"
      :total-records="count"
      :rows="limit"
      :paginator="true"
      v-model:expanded-rows="expandedRows"
      v-model:filters="filters"
      v-model:selection="selectedRow"
      responsive-layout="scroll"
      :loading="loading"
      :lazy="true"
      :filter-display="getDisplayFilter()"
      :rows-per-page-options="[5, 10, 50, 100]"
      current-page-report-template="{first} - {last} из {totalRecords}"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      @page="onEvent($event)"
      @filter="onEvent($event)"
      @sort="onEvent($event)"
      sort-mode="multiple"
      :show-gridlines="true"
      removableSort
    >
      <template #empty>
        <div style="text-align: center">
          <span>Нет данных</span>
        </div>
      </template>
      <template #header v-if="!hideHeader">
        <div class="flex justify-content-between">
          <span class="p-buttonset" v-if="getDisplayFilter() === 'row'">
            <Button
              type="button"
              icon="pi pi-filter"
              label="Применить фильтры"
              class="p-button-outlined"
              @click="runFilters"
            ></Button>
            <Button
              type="button"
              icon="pi pi-filter-slash"
              label="Сбросить фильтры"
              class="p-button-outlined"
              @click="clearFilters"
            ></Button>
          </span>
          <Button
            v-if="getDisplayFilter() === 'menu'"
            type="button"
            icon="pi pi-filter-slash"
            label="Сбросить фильтры"
            class="p-button-outlined"
            @click="clearFilters"
          ></Button>
          <slot name="additionalButtons" :selection="selectedRow"></slot>
        </div>
      </template>
      <Column selectionMode="single" headerStyle="width: 3em" v-if="select"></Column>
      <Column :expander="true" v-if="expander" headerStyle="width: 3em"></Column>
      <Column
        v-for="field in fields"
        :key="field.code"
        :field="field.code"
        :header="field.header"
        :sortable="field.sortable"
        :show-filter-match-modes="false"
        :show-filter-menu="getDisplayFilter() === 'menu'"
      >
        <template #body="{data}">
          <slot :name="field.code" :data="data">{{ data[field.code] }}</slot>
        </template>
        <template v-if="field.filter !== undefined" #filter="{filterModel, filterCallback}">
          <p v-if="field.filter.placeholder && displayFilter === 'menu'"><strong>{{ field.filter.placeholder }}</strong></p>
          <Calendar
            v-if="field.filter.type === 'dateInterval'"
            v-model="filterModel.value"
            class="p-column-filter"
            @keydown.enter="filterCallback()"
            selection-mode="range"
            date-format="dd.mm.yy"
          ></Calendar>
          <InputText
            v-if="field.filter.type === 'text'"
            v-model="filterModel.value"
            class="p-column-filter"
            @keydown.enter="filterCallback()"
          ></InputText>
          <InputMask
              v-if="field.filter.type === 'phone'"
              v-model="filterModel.value"
              class="p-column-filter"
              :mask="field.filter.mask"
          ></InputMask>
          <InputNumber
            v-if="field.filter.type === 'number'"
            v-model="filterModel.value"
            class="p-column-filter"
            :use-grouping="field.filter.group"
            @keydown.enter="filterCallback()"
          ></InputNumber>
          <Dropdown
            filter
            v-if="field.filter.type === 'select'"
            v-model="filterModel.value"
            :options="field.filter.items"
            :option-value="field.filter.optionValue"
            :option-label="field.filter.optionLabel"
            @keydown.enter="filterCallback()"
          ></Dropdown>
        </template>
        <template v-if="field.filter" #filterclear="{filterCallback}">
          <Button type="button" icon="pi pi-times" @click="filterCallback()" class="p-button-secondary"></Button>
        </template>
        <template v-if="field.filter" #filterapply="{filterCallback}">
          <Button type="button" icon="pi pi-check" @click="filterCallback()" class="p-button-success"></Button>
        </template>
      </Column>
      <template #expansion="row">
        <slot name="expand" :row="row"></slot>
      </template>
    </DataTable>
  </div>
</template>

<script>
import tableService from "@/service/TableService";

export default {
  name: "AbstractTable",
  props: {
    disableAutoload: {
      type: Boolean,
      required: false
    },
    hideHeader: {
      type: Boolean,
      required: false
    },
    defaultData: {
      type: Object,
      required: false
    },
    displayFilter: {
      type: String,
      required: false,
    },
    expander: {
      type: Boolean,
      required: false
    },
    select: {
      type: Boolean,
      required: false,
    },
    code: {
      type: String,
      required: true,
    },
    fieldsBase: {
      type: Array,
    },
    ignoredFields: {
      type: Array
    }
  },
  computed: {
    fields: function () {
      let fields = null
      if (this.code === '' || this.code === null) {
        fields = this.fieldsBase
      }
      if (fields === null) {
        fields = this.$store.getters.table(this.code)
      }
      let ignored = this.ignoredFields
      if (!ignored) {
        ignored = []
      }
      return fields.filter(field => !ignored.includes(field.code))
    }
  },
  data() {
    return {
      sortField: null,
      selectedRow: {},
      loading: true,
      expandedRows: [],
      limit: 10,
      items: [],
      count: 0,
      params: {},
    }
  },
  methods: {
    runFilters() {
      this.$refs.dt.onFilterApply()
    },
    clearFilters(load = true) {
      let filters = {}
      for (let i in this.fields) {
        if (this.fields[i].filter !== undefined) {
          filters[this.fields[i].code] = {
            value: '',
            matchMode: 'equals'
          }
        }
      }
      this.filters = filters
      this.params.filters = this.filters
      if (load) {
        this.getData()
      }
    },
    getData() {
      this.loading = true
      let search = {}
      if (this.params.filters !== null) {
        for (let key in this.filters) {
          if (this.params.filters[key]['value'] !== '' && this.params.filters[key]['value'] !== null) {
            search[key] = this.params.filters[key]['value']
          }
        }
      }
      for (let ddKey in this.defaultData) {
        search[ddKey] = this.defaultData[ddKey]
      }
      search['sort'] = this.params.multiSortMeta
      tableService.getTable(this.code, this.params.rows, this.params.first, search).then((res) => {
        this.count = res.count
        this.items = res.data
        this.loading = false
      }).catch(() => {
        this.$toast.add({
          severity: 'error',
          summary: 'Ошибка',
          detail: 'Ошибка получения данных для таблицы',
          life: 2000
        })
        this.count = 0
        this.items = []
        this.loading = false
      })
    },
    onEvent(event) {
      this.params = event
      this.getData()
    },
    getDisplayFilter() {
      if (this.displayFilter === 'menu') {
        return 'menu'
      }
      return 'row'
    },
    setLoading(value) {
      this.loading = value
    }
  },
  beforeCreate() {
    let filters = {}
    for (let i in this.fields) {
      if (this.fields[i].filter !== undefined) {
        filters[this.fields[i].code] = {
          value: '',
          matchMode: 'equals'
        }
      }
    }
    this.filters = filters
  },
  mounted() {
    this.params = {
      first: 0,
      rows: this.limit,
      sortField: null,
      sortOrder: null,
      filters: null
    }
    let load = true
    if (this.disableAutoload) {
      load = false
      this.loading = false
    }
    this.clearFilters(load)
  }
}
</script>

<style scoped>

</style>