import rpc from "@/rpc";

class TableService {
    rpc

    constructor(rpc) {
        this.rpc = rpc
    }

    getTable(table, limit, offset, params = {}) {
        let req = {}
        Object.assign(req, params)
        req['table'] = table
        req['limit'] = limit
        req['offset'] = offset
        return this.rpc.execute('getTable', req)
    }
}

export default new TableService(rpc)