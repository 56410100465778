<template>
  <div class="grid">
    <div class="col-12">
      <Card>
        <template #title>Список удалённых заявок / займов</template>
        <template #content>
          <AbstractTable ref="dataTable" code="deleteActions" display-filter="menu"></AbstractTable>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import AbstractTable from "@/components/AbstractTable";
export default {
  name: "DeletedList",
  components: {AbstractTable},
  methods: {
    refreshTable() {
      this.$refs.dataTable.getData()
    }
  }
}
</script>

<style scoped>

</style>